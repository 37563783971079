<template>
  <div>
    <nav class="navbar navbar-light navbar-expand-lg">
      <router-link to="/">
        <div id="logo"></div>
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mx-auto text-center">
          <li class="nav-item">
              <router-link class="text-uppercase pr-2" id="btn-home" :to="{path:'/'}">Inicio</router-link>
          </li>
          <li class="nav-item dropdown">
            <a
              href="#"
              class="nav-link dropdown-toggle text-uppercase d-none d-lg-block d-xl-block"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >dónde ir</a>
            <router-link class="text-uppercase d-block d-sm-block d-md-block d-lg-none" :to="{path:'destinos'}">dónde ir</router-link>
            <div
              class="dropdown-menu dropdown-menu-destinos shadow-sm"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <div class="row dropdown-destinos">
                <div class="col-md" v-for="i in 6" :key="i">
                  <div v-for="j in 10" :key="j">
                    <router-link
                      class="dropdown-item pl-3 cursor-pointer"
                      :to="{path: `/destino/${destinos[j-1].id}/${destinos[j-1].nombre.replace(/\s/g,'-')}`}"
                      v-if="destinos[j-1] && i==1"
                    >{{destinos[j-1].nombre}}</router-link>
                    <router-link
                      class="dropdown-item pl-0 cursor-pointer"
                      :to="{path: `/destino/${destinos[j+(i*10)-1-10].id}/${destinos[j+(i*10)-1-10].nombre.replace(/\s/g,'-')}` }"
                      v-if="destinos[j+(i*10)-1-10] && i>1"
                    >{{destinos[j+(i*10)-1-10].nombre}}</router-link>
                  </div>
                </div>
                  <router-link
                  id="btn-ver-mas"
                      class="dropdown-item cursor-pointer text-center ml-4 mr-4 border mt-1"
                      :to="{path: '/destinos' }"
                    >Ver más destinos</router-link>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a
              href="#"
              class="nav-link dropdown-toggle text-uppercase"
              id="dropdownQueHacer"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >qué hacer</a>
            <div class="dropdown-menu shadow-sm" aria-labelledby="dropdownQueHacer">
              <div class="row dropdown-container">
                <div class="col-md-6">
                  <span class="dropdown-title pl-3">Actividades</span>
                  <div v-for="actividad in this.actividades" :key="actividad.id">
                    <router-link
                      class="dropdown-item"
                      :to="{path: `/actividades/${actividad.url}`}"
                    >{{actividad.nombre}}</router-link>
                  </div>
                </div>
                <div class="col-md-6">
                  <span class="dropdown-title pl-3">Otros</span>
                  <div v-for="actividad in this.otras_actividades" :key="actividad.id">
                    <router-link class="dropdown-item" :to="{path: `/${actividad.url}`}">{{actividad.nombre}}</router-link>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a
              href="#"
              class="nav-link dropdown-toggle text-uppercase"
              id="navbarDropdownMenuLink2"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >información</a>
            <div
              class="dropdown-menu shadow-sm dropdown-menu-single"
              aria-labelledby="navbarDropdownMenuLink2"
            >
              <div class="row dropdown-container">
                <div class="col-md-12">
                  <span class="dropdown-title pl-3">Información</span>
                  <div v-for="info in this.informacion" :key="info.id">
                    <router-link class="dropdown-item" :to="{path:`/${info.url}`}">{{info.nombre}}</router-link>
                  </div>  
                </div>
              </div>
            </div>
          </li>
<!--          <li class="nav-item dropdown">
            <a
              href="#"
              class="nav-link dropdown-toggle text-uppercase"
              id="navbarDropdownMenuLink2"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >COVID-19</a>
            <div
              class="dropdown-menu shadow-sm dropdown-menu-single"
              aria-labelledby="navbarDropdownMenuLink2"
            >
              <div class="row dropdown-container">
                <div class="col-md-12">
                  <span class="dropdown-title pl-3">Protocolos</span>
                  <div>
                    <a
                      class="dropdown-item"
                      target="_BLANK"
                      href="https://www.argentina.gob.ar/turismoydeportes/protocolos-covid-turismo-deportes"
                    >Nacionales</a>
                  </div>
                  <div>
                    <router-link class="dropdown-item" :to="{path:'/protocolos-covid'}">Provinciales</router-link>
                  </div>
                </div>
              </div>
            </div>
          </li>-->
        </ul>
        <form class="my-2 my-lg-0 search-bar-class">
          <input
            id="search-bar"
            class="form-control m-0 w-100"
            type="search"
            style="display:block"
            placeholder="buscar"
            v-model="inputSearch"
            aria-label="Search"
            maxlength="120"
            autocapitalize="off"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            tabindex="3"
          />
          <ul class="w-100 list-group" style="position:absolute;z-index: 3">
            <router-link :to="optSearch.url" class="text-decoration-none text-capitalize text-muted" v-for="optSearch in lev" :key="optSearch.nombre"><li class="list-group-item li-search cursor-pointer">{{optSearch.nombre}}</li></router-link>
          </ul>
        </form>
      </div>
    </nav>
  </div>
</template>

<script>
import searchJSON from "../assets/info/search.json"
import Api from "../providers/api";
import "../tools/levenstein";
export default {
  name: "Navbar",
  data() {
    return {
      inputSearch: "",
      ciudades: [
        {
          id: "1",
          nombre: "Paraná",
          url: "parana",
        },
        {
          id: "2",
          nombre: "Gualeguaychú",
          url: "gualeguaychu",
        },
        {
          id: "3",
          nombre: "Concordia",
          url: "concordia",
        },
        {
          id: "4",
          nombre: "Colón",
          url: "colon",
        },
      ],
      destinos: [],
      actividades: [
        {
          id: "1",
          nombre: "Turismo de bienestar",
          url: "turismo-de-bienestar",
        },
        {
          id: "2",
          nombre: "Turismo en naturaleza",
          url: "turismo-en-naturaleza",
        },
        {
          id: "3",
          nombre: "Turismo enogastronómico",
          url: "turismo-enogastronomico",
        },
        {
          id: "4",
          nombre: "Turismo de reuniones",
          url: "turismo-de-reuniones",
        },
        {
          id: "5",
          nombre: "Turismo cultura-historia",
          url: "turismo-cultura-historia",
        },
        {
          id: "6",
          nombre: "Fiestas populares",
          url: "fiestas-populares",
        },
        {
          id: "7",
          nombre: "Playas",
          url: "playas",
        },
        {
          id: "8",
          nombre: "Carnaval",
          url: "carnaval",
        },
        {
          id: "10",
          nombre: "Golf",
          url: "golf",
        },
        {
          id: "11",
          nombre: "Eventos deportivos",
          url: "eventos-deportivos",
        },
 /*        {
          id: "12",
          nombre: "Turismo social",
          url: "turismo-social",
        },*/
       {
          id: "13",
          nombre: "Turismo rural",
          url: "turismo-rural",
        },
        {
          id: "14",
          nombre: "Pesca deportiva",
          url: "pesca-deportiva",
        },
      ],
      otras_actividades: [
        {
          id: "1",
          nombre: "Agenda",
          url: "agenda",
        },
        {
          id: "2",
          nombre: "Itinerarios",
          url: "itinerarios",
        },
        {
          id: "3",
          nombre: "Promociones",
          url: "promociones",
        },
        {
          id: "4",
          nombre: "Agencias de Viaje",
          url: "agencias-de-viaje",
        },
        {
          id: "5",
          nombre: "Transporte",
          url: "transporte",
        },
        {
          id: "6",
          nombre: "Otros servicios",
          url: "otros-servicios",
        },
        {
          id: "9",
          nombre: "Turismo accesible",
          url: "actividades/turismo-accesible",
        },
      ],
      informacion: [
        {
          id: "1",
          nombre: "Contacto",
          url: "contacto",
        },
        {
          id: "2",
          nombre: "Institucional",
          url: "institucional",
        },
        {
          id: "3",
          nombre: "Noticias",
          url: "noticias",
        },
      ],
    };
  },
  computed: {
    lev() {
      if(this.inputSearch){
      let destinos = searchJSON;
      destinos = destinos.map( e => {
        return {
          ...e,
          levenstein: this.inputSearch.levenstein(e.nombre)
        }
      })
      destinos = destinos.filter(x => x.levenstein < 3).sort((a,b) => a.levenstein - b.levenstein).slice(0,4)
      return destinos;
      }else{
        return []
      }
    },
  },
  methods: {
    getDestinosNavbar(){
      const Destinos = Api("destinos-nav");
      Destinos.get().then((response) => {
        this.destinos = response.success ? response.destinos : [];
      });
    }
  },
  mounted (){
    this.getDestinosNavbar();
  }
};
</script>

<style scoped>

#btn-ver-mas{
  color: var(--azul);
  text-transform: lowercase;
}

#btn-ver-mas:first-letter{
  text-transform: uppercase;
}
.navbar {
  background-color: #ffffff;
  max-width: 90%;
  height: 5em;
  margin: auto !important;
  top: -5em;
}

.navbar-nav .nav-item a {
  color: var(--azul-5);
  font-weight: bold;
  border-right: 0.1em solid rgb(0, 174, 244);
  line-height: 0.4em;
}

#search-bar {
  background-color: rgba(108, 109, 112, 1);
  color: #f8f8f8;
  height: 2em;
  max-width: 20em;
}

#search-bar::placeholder {
  color: #ffffff;
}

#logo {
  background-image: url("../assets/logo_color.png");
  background-size: cover;
  height: 5em;
  width: 16.5em;
}

.dropdown-item {
  padding: 1em;
  text-transform: capitalize;
  display: block;
  font-weight: 400 !important;
}

.btn-home{
  text-decoration: none !important;
  color: var(--gris-3);
}
.nav-item a{
  text-decoration: none;
}

.dropdown-item:hover {
  background: var(--gris-3);
}


.dropdown-menu {
  margin-top: 1.7em;
  border-radius: 0;
  border: 0px;
  min-width: 29em;
}

.dropdown-title {
  font-weight: bold;
  display: flex;
  color: var(--azul-4);
}

.dropdown-menu-single {
  min-width: 14em !important;
}

.dropdown-container {
  padding-right: 0.3em;
}

.dropdown-toggle::after {
  content: none;
}

.dropdown-destinos {
  width: 87em;
}

.dropdown-container .col-md {
  padding-left: 0;
  padding-right: 0;
}

.dropdown-menu-destinos {
  left: 150% !important;
  right: auto !important;
  transform: translate(-42%, 0) !important;
}

.search-bar-class{
  position: relative;
  width: 25em;
}

@media (max-width: 1023px) {
  #logo {
    height: 4em;
    width: 13.5em;
  }

  .search-bar-class{
    margin-left: auto !important;
    margin-right: auto !important;
    width: 10em;
  }

  .navbar-collapse {
    background-color: var(--gris-suave);
    z-index: 9;
  }

  #search-bar {
    margin-bottom: 1em;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .nav-item {
    padding: 1em;
  }

  .navbar-nav .nav-item a {
    border: none;
  }
}

@media (max-width: 890px) {
  #logo {
    height: 4em;
    width: 13.5em;
  }

  .dropdown-menu {
    min-width: 10em !important;
  }
}

@media (max-width: 414px) {
  #logo {
    height: 3em;
    width: 10.5em;
  }

  #search-bar {
    max-width: 12em;
  }
}

.li-search:hover{
  background-color: var(--azul-3);
  color: #FFF
}

</style>
